import { DirectDepositSplitSchema } from '../schema'
import { LegacyChangeAmountDepositRulesRequest } from './api-documents'

export const convertFormSubmissionToAmountRequest = (
  input: DirectDepositSplitSchema
): LegacyChangeAmountDepositRulesRequest | undefined => {
  const lastBankAccount = input.bankAccounts.pop()

  if (!!lastBankAccount) {
    return {
      depositRules: input.bankAccounts.map((bankAccount) => {
        return {
          bankAccountId: bankAccount.id,
          amount: bankAccount.amount.toString()
        }
      }),
      remainderBankAccountId: lastBankAccount.id
    }
  }
}
