import * as React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { WarningOutlineDisplayIcon } from '@toasttab/buffet-pui-icons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

type ConfirmationModalButtonProps = {
  'data-toast-track-id'?: string
  text?: string
  onClick: VoidFunction
}

/** a confirmation modal that has the same styling as the error modal from buffet with an additional cancel button and tracking */
export type ConfirmationModalProps = {
  isOpen: boolean
  showIcon?: boolean
  heading: string
  destructive?: boolean
  confirmButtonProps: ConfirmationModalButtonProps
  cancelButtonProps: ConfirmationModalButtonProps
} & TestIdentifiable

export const ConfirmationModal: React.FunctionComponent<
  React.PropsWithChildren<ConfirmationModalProps>
> = ({
  testId = 'confirmation-modal',
  isOpen = false,
  showIcon = true,
  heading = '',
  destructive = false,
  confirmButtonProps,
  cancelButtonProps,
  children
}) => {
  const isMobile = useScreenSize() < ScreenSize.MD

  return (
    <Modal
      isOpen={isOpen}
      size='xl'
      position='pin-center'
      testId={testId.toString()}
    >
      <ModalHeader>
        <div className='flex items-center'>
          {showIcon && (
            <WarningOutlineDisplayIcon
              testId={`${testId}-icon`}
              className='mr-4'
              size='lg'
              accessibility='decorative'
            />
          )}
          <div className='type-headline-5'>{heading}</div>
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter stackOnMobile>
        <Button
          testId={`${testId}-cancel`}
          data-toast-track-id={cancelButtonProps['data-toast-track-id']}
          variant={isMobile ? 'secondary' : 'link'}
          className='w-full sm:w-auto'
          onClick={cancelButtonProps.onClick}
        >
          {cancelButtonProps.text || 'Cancel'}
        </Button>
        <Button
          testId={`${testId}-confirm`}
          data-toast-track-id={confirmButtonProps['data-toast-track-id']}
          variant={destructive ? 'destructive' : undefined}
          className='w-full sm:w-auto'
          onClick={confirmButtonProps.onClick}
        >
          {confirmButtonProps.text || 'Confirm'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
