import * as React from 'react'
import {
  RadioUnselectedIcon,
  RadioSelectedIcon
} from '@toasttab/buffet-pui-icons'
import { useUniqueId } from '@toasttab/buffet-utils'
import { RadioButtonProps } from '@toasttab/buffet-pui-radio-group'
import { CtaProps, CtaSelectionInput } from '@local/cta-selection-input'

export type CtaRadioButtonProps = { cta?: CtaProps } & RadioButtonProps

export const CtaRadioButton = React.forwardRef<
  HTMLInputElement,
  CtaRadioButtonProps
>(({ testId, name, ...props }, ref) => {
  testId = useUniqueId(testId, 'radio-button-')

  return (
    <CtaSelectionInput
      testId={testId}
      {...props}
      name={name}
      ref={ref}
      type='radio'
      uncheckedIcon={<RadioUnselectedIcon accessibility='decorative' />}
      checkedIcon={<RadioSelectedIcon accessibility='decorative' />}
    />
  )
})

CtaRadioButton.displayName = 'CtaRadioButton'
