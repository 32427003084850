import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEmployee, useUser } from '@local/contexts'
import { useRoutedFromOnboardingChecklist } from '@local/hooks'
import {
  BANK_ACCOUNTS_QUERY_KEY,
  PAYMENT_METHOD_QUERY_KEY
} from '../../../../api-documents'

export type SaveBankAccountMutationProps = {
  plaidPublicToken: string
  bankName?: string
}

export const useSaveBankAccountMutation = (
  onSuccess?: VoidFunction,
  onError?: VoidFunction
) => {
  const queryClient = useQueryClient()

  const client = useUser().client
  const employeeId = useEmployee().id
  const isFromOnboardingChecklist = useRoutedFromOnboardingChecklist()

  return useMutation({
    mutationFn: (props: SaveBankAccountMutationProps) =>
      fetch(
        `/${client}/employees/${employeeId}/plaidBankAccount?onboardingChecklist=${isFromOnboardingChecklist}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/com.toasttab.payroll.v1+json',
            'Content-Type': 'application/com.toasttab.payroll.v1+json',
            'Cache-Control': 'no-store'
          },
          body: JSON.stringify({
            plaidPublicToken: props.plaidPublicToken,
            bankName: props.bankName
          })
        }
      )
        .catch(onError)
        .then((response) => {
          if (!!response && !response.ok) {
            response.json().then((responseJson) => {
              if (
                responseJson.errors.some(
                  (error: string) => error === 'Reauthentication required'
                )
              ) {
                window.location.reload()
              } else {
                onError && onError()
              }
            })
          } else {
            onSuccess && onSuccess()
            queryClient.invalidateQueries({
              queryKey: [PAYMENT_METHOD_QUERY_KEY, client, employeeId]
            })
            queryClient.invalidateQueries({
              queryKey: [BANK_ACCOUNTS_QUERY_KEY, client, employeeId]
            })
          }
        })
  })
}
