import { ApolloClient, InMemoryCache } from '@apollo/client'
// import { StrictTypedTypePolicies } from './generated/helpers'

export const getClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Customer: {
          keyFields: ['uuid']
        }
      } // todo  as StrictTypedTypePolicies
    }),
    uri: '/graphql'
  })
}

export const client = getClient()
