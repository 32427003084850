import * as React from 'react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { trackDirectDepositAccounts } from '@local/track'
import { BankAccount } from '../../../api-documents'
import {
  Currency,
  formatCurrency,
  formatPercent
} from '@toasttab/buffet-pui-number-utilities'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { useEmployeeDirectDepositBankAccounts } from '@local/contexts'
import { DeleteBankAccountConfirmationModal } from './delete-bank-account-confirmation-modal'
import { t } from '@local/translations'

const columnHelper = createColumnHelper<BankAccount>()

/**
 * The column definition for displaying direct deposit bank account information
 * includes which data should be displayed in which columns
 */
export const useBankAccountsTableColumns = (): ColumnDef<
  BankAccount,
  any
>[] => {
  const bankAccounts = useEmployeeDirectDepositBankAccounts()
  const formatAmount = useFormatAmount()
  return React.useMemo(
    () => [
      columnHelper.accessor('bankName', {
        id: 'bank-name',
        header: t('direct-deposit-accounts-table.bank-name'),
        cell: (info) => info.getValue()
      }),
      columnHelper.accessor('accountNumber', {
        id: 'account-number',
        header: t('direct-deposit-accounts-table.account-number'),
        cell: (info) => info.getValue()
      }),
      columnHelper.accessor((originalRow) => formatAmount(originalRow), {
        id: 'amount',
        header: t('direct-deposit-accounts-table.distribution'),
        cell: (info) => info.getValue()
      }),
      columnHelper.accessor((originalRow) => originalRow, {
        id: 'delete',
        header: undefined,
        cell: (info) => {
          return (
            <DeleteButton
              bankId={info.getValue().id}
              bankName={info.getValue().bankName}
            />
          )
        }
      })
    ],
    [bankAccounts]
  )
}

const useFormatAmount = (): ((
  bankAccount: BankAccount
) => string | undefined) => {
  const { locale } = useBuffetContext()

  const oneHasAmount =
    useEmployeeDirectDepositBankAccounts().filter(
      (account) => !!account.amount && account.amount > 0
    ).length > 0

  return (bankAccount: BankAccount): string | undefined => {
    if (bankAccount.percentage === 100 && oneHasAmount) {
      return t('direct-deposit-accounts-table.amount-column.remainder-pay')
    }
    if (oneHasAmount) {
      return formatCurrency(
        { amount: bankAccount.amount, currency: Currency.USD },
        locale
      )
    }
    return formatPercent(bankAccount.percentage / 100, locale)
  }
}

const DeleteButton: React.FunctionComponent<{
  bankId: string
  bankName: string
}> = ({ bankId, bankName }) => {
  const [deleteAccountId, setDeleteAccountId] = React.useState<string | null>(
    null
  )

  return (
    <>
      {!!deleteAccountId && (
        <DeleteBankAccountConfirmationModal
          bankAccountId={deleteAccountId}
          onClose={() => setDeleteAccountId(null)}
        />
      )}
      <div className='flex justify-end '>
        <IconButton
          cropToIcon={true}
          icon={
            <DeleteIcon
              {...trackDirectDepositAccounts('initiate-delete')}
              aria-label={t(
                'direct-deposit-accounts-table.delete-button-aria-label',
                { bankName: bankName }
              )}
            />
          }
          onClick={() => setDeleteAccountId(bankId)}
          textClassName='text-secondary'
        />
      </div>
    </>
  )
}
