import {
  useEmployee,
  useIsDirectDepositSelected,
  useIsViewingSelf
} from '@local/hooks'
import { useShowPayCardDisclaimer } from '../payCard/utils'

export const useShowDirectDepositDisclaimer = (): boolean => {
  const isViewingSelf = useIsViewingSelf()
  const showPayCardDisclaimer = useShowPayCardDisclaimer()
  const isDirectDepositSelected = useIsDirectDepositSelected()
  const isLegacyDirectDeposit =
    useEmployee().paymentMethod === 'PayByLegacyDirectDeposit'

  return (
    isViewingSelf &&
    isDirectDepositSelected &&
    !isLegacyDirectDeposit &&
    !showPayCardDisclaimer
  )
}
