import * as React from 'react'
import { isMobile } from 'react-device-detect'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { trackDisclaimer } from '@local/track'
import { useShowPayCardDisclaimer } from './utils'
import { t, Trans } from '@local/translations'

export const PayCardDisclaimer: React.FunctionComponent = ({
  testId = 'payment-method-pay-card-disclaimer'
}: TestIdentifiable) => {
  const showPayCardDisclaimer = useShowPayCardDisclaimer()

  return showPayCardDisclaimer ? (
    <section className='pl-4 pr-4 mt-4 mb-16 md:mt-8' data-testid={testId}>
      <section className='border-b-2'>
        <div className='mt-10 md:mt-5 type-caption text-gray-75'>
          {t('direct-deposit-disclaimer.1')}
        </div>
        <div className='my-10 md:my-5 type-caption text-gray-75'>
          {t('direct-deposit-disclaimer.2')}
        </div>
      </section>
      <section>
        <div className='mt-10 md:mt-5 type-caption text-gray-75'>
          <Trans
            i18nKey={'pay-card-disclaimer.sutton-bank'}
            components={[
              <a
                {...trackDisclaimer(
                  'sutton-bank-disclaimer.pay-card-terms-link'
                )}
                href='https://pos.toasttab.com/products/toast-pay-card-and-pay-out/#availability'
                key='pay-card-link'
                rel='noopener noreferrer'
                target='_blank'
              />
            ]}
          />
        </div>
        {!isMobile && (
          <>
            <div className='mt-10 md:mt-5 type-caption text-gray-75'>
              {t('pay-card-disclaimer.1')}
            </div>
            <div className='mt-10 md:mt-5 type-caption text-gray-75'>
              {t('pay-card-disclaimer.2')}
            </div>
          </>
        )}
        {isMobile && (
          <div className='mt-10 md:mt-5 text-center type-caption'>
            <a
              {...trackDisclaimer('pay-card-terms-link')}
              href='https://pos.toasttab.com/products/toast-pay-card-and-pay-out/'
              target='_blank'
              rel='noreferrer'
            >
              {t('pay-card-disclaimer.terms-apply')}
            </a>
          </div>
        )}
      </section>
    </section>
  ) : null
}
