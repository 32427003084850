import React from 'react'
import { ErrorPageMissingInfo } from '@local/error-pages'
import { trackMissingEmployeeInfo } from '@local/track'
import { useNewHireNextStepLink } from './api-documents/newHireNextStepLinkQuery'
import { t } from '@local/translations'

export const MissingEmployeeInfoPage = () => {
  const { data: nextStepLink } = useNewHireNextStepLink()

  return (
    <ErrorPageMissingInfo
      {...trackMissingEmployeeInfo('new-hire-link')}
      heading={t('missing-employee-data.heading')}
      subheading={t('missing-employee-data.subheading')}
      buttonLabel={t('missing-employee-data.button')}
      href={nextStepLink}
    />
  )
}
