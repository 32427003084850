import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEmployee, useUser } from '@local/contexts'
import { useRoutedFromOnboardingChecklist } from '@local/hooks'
import { PaymentMethod } from '../../../../api-documents'

export type PaymentMethodMutationProps = {
  paymentMethod: PaymentMethod
}

export const usePaymentMethodMutation = (
  onSuccess?: VoidFunction,
  onError?: VoidFunction
) => {
  const queryClient = useQueryClient()

  const client = useUser().client
  const employeeId = useEmployee().id
  const isFromOnboardingChecklist = useRoutedFromOnboardingChecklist()

  return useMutation({
    mutationFn: (props: PaymentMethodMutationProps) =>
      fetch(
        `/${client}/employees/${employeeId}/payMethod?onboardingChecklist=${isFromOnboardingChecklist}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/com.toasttab.payroll.v1+json',
            'Content-Type': 'application/com.toasttab.payroll.v1+json',
            'Cache-Control': 'no-store'
          },
          body: JSON.stringify({ paymentType: props.paymentMethod })
        }
      )
        .catch(onError)
        .then((response) => {
          if (!!response && !response.ok) {
            response.json().then((responseJson) => {
              if (
                responseJson.errors.some(
                  (error: string) => error === 'Reauthentication required'
                )
              ) {
                window.location.reload()
              } else {
                onError && onError()
              }
            })
          } else {
            onSuccess && onSuccess()
            queryClient.invalidateQueries({
              queryKey: ['payment-method', client, employeeId]
            })
          }
        })
  })
}
