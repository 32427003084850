import * as React from 'react'
import { Form, FormikProvider, useFormik } from 'formik'

import { Modal } from '@toasttab/buffet-pui-modal'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { TextToggleSwitchField } from '@toasttab/buffet-pui-forms'

import { trackDirectDepositSplitModal, useTracking } from '@local/track'
import { useEmployeeDirectDepositBankAccounts } from '@local/contexts'
import { DirectDepositSplitSchema, useDirectDepositSplitSchema } from './schema'

import { AmountSplitTable } from './amount-split'
import { PercentSplitTable } from './percent-split'
import { useSubmit } from './utils'
import { t } from '@local/translations'

export type DirectDepositSplitModalProps = {
  onClose: () => void
} & TestIdentifiable

export const DirectDepositSplitModal: React.FunctionComponent<
  DirectDepositSplitModalProps
> = ({ onClose, testId = 'direct-deposit-split-modal' }) => {
  const directDepositBankAccounts = useEmployeeDirectDepositBankAccounts()
  const isBankAccountsSplitByAmount = React.useMemo(
    () =>
      directDepositBankAccounts.some((acct) => acct.amount && acct.amount > 0),
    [directDepositBankAccounts]
  )

  const directDepositSplitSchema = useDirectDepositSplitSchema()

  const onSubmit = useSubmit(onClose)
  const formik = useFormik<DirectDepositSplitSchema>({
    enableReinitialize: true,
    initialValues: {
      isSplitByAmount: isBankAccountsSplitByAmount,
      bankAccounts: directDepositBankAccounts
    },
    onSubmit: (values) => onSubmit(values),
    validationSchema: directDepositSplitSchema
  })

  const { errors, handleSubmit, isSubmitting, setFieldTouched, values } = formik

  React.useEffect(() => {
    // forces validation after enableReinitialize is complete
    // use case is opening the modal immediately after adding another account
    // https://github.com/jaredpalmer/formik/issues/2457
    setTimeout(() => setFieldTouched('bankAccounts[0].percent', true), 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directDepositBankAccounts])

  const { track } = useTracking()
  return (
    <FormikProvider value={formik}>
      <Form id='direct-deposit-split-form' onSubmit={handleSubmit}>
        <Modal
          isOpen={true}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          onRequestClose={() => {
            track('employee-payment-method.direct-deposit-split-modal.close')
            onClose()
          }}
          size='xl'
          position='pin-center'
          testId={`${testId}`}
        >
          <Modal.Header>{t('direct-deposit-split-modal.header')}</Modal.Header>
          <Modal.Body className='mt-4'>
            <div className='space-y-6'>
              <SplitMethodToggle />
              {typeof errors.bankAccounts === 'string' && (
                <Alert className='w-full' variant='error'>
                  {errors.bankAccounts}
                </Alert>
              )}
              {values.isSplitByAmount ? (
                <AmountSplitTable />
              ) : (
                <PercentSplitTable />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              {...trackDirectDepositSplitModal('save')}
              testId='save-split-method-button'
              disabled={isSubmitting || errors.bankAccounts !== undefined}
              form='direct-deposit-split-form'
              type='submit'
              variant={'primary'}
            >
              {t('direct-deposit-split-modal.save')}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </FormikProvider>
  )
}

export const SplitMethodToggle: React.FunctionComponent = () => {
  return (
    <div className='flex justify-between items-center'>
      <div>
        <div className='font-semibold type-default text-default'>
          {t('direct-deposit-split-modal.split-method')}
        </div>
        <div className='text-default'>
          {t('direct-deposit-split-modal.description')}
        </div>
      </div>
      <TextToggleSwitchField
        {...trackDirectDepositSplitModal('toggle-switch')}
        testId='toggle-switch'
        containerClassName='w-28 md:w-20 ml-4'
        hideLabel={true}
        label={t('direct-deposit-split-modal.split-method')}
        options={['%', '$']}
        name={'isSplitByAmount'}
      />
    </div>
  )
}
