import * as yup from 'yup'
import parsePhoneNumber from 'libphonenumber-js'
import { t } from '@local/translations'

export type SendDownloadAppLinkSchema = {
  phoneNumber: string
}

export const useSendDownloadAppLinkSchema = () => {
  return yup.object({
    phoneNumber: yup
      .string()
      .required(t('download-app-modal.phone-number-input.error'))
      .test('isValidPhoneNumber', 'isNotValidPhoneNumber', (value?: string) => {
        const testPhoneNumber = value && parsePhoneNumber(value, 'US')
        return !!(testPhoneNumber && testPhoneNumber?.isValid())
      })
  })
}
