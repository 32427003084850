import React, { cloneElement, useEffect } from 'react'
import cx from 'classnames'
import {
  isWithAriaLabelledBy,
  isWithLabel,
  Label,
  SelectionInputProps,
  useFocus
} from '@toasttab/buffet-pui-input-base'
import { useUniqueId } from '@toasttab/buffet-utils'
import { Button } from '@toasttab/buffet-pui-buttons'

export type CtaProps = {
  'data-toast-track-id'?: string
  label: string
  href: string
  stacked?: boolean
}

export type CtaSelectionInputProps = { cta?: CtaProps } & SelectionInputProps

export const CtaSelectionInput = React.forwardRef<
  HTMLInputElement,
  CtaSelectionInputProps
>(
  (
    {
      checked = false,
      cta,
      disabled = false,
      hideLabel = false,
      testId,
      onChange,
      onBlur,
      onFocus,
      type,
      checkedIcon,
      uncheckedIcon,
      containerClassName,
      subLabel,
      ...restProps
    },
    ref
  ) => {
    const { focused, ...focusHandlers } = useFocus({ onBlur, onFocus })
    const label = isWithLabel(restProps) && restProps.label
    const ariaLabelledBy =
      isWithAriaLabelledBy(restProps) && restProps['aria-labelledby']

    const id = useUniqueId(restProps.id, 'selection-input-')
    const uniqueTestId = useUniqueId(testId, 'selection-input-')

    useEffect(() => {
      if (label && typeof label !== 'string' && hideLabel) {
        throw new Error(
          "Oops! You can't use a non-string item as a label when hideLabel is true"
        )
      }
    }, [label, hideLabel])

    return (
      <div
        data-testid={`${uniqueTestId}-container`}
        className={cx(
          'flex relative items-start pl-px overflow-visible select-none group',
          containerClassName,
          {
            'cursor-default pointer-events-none': disabled
          }
        )}
      >
        <div
          data-testid={uniqueTestId}
          className={cx(
            'inline-flex w-10 h-10 mr-1 my-0.5 -ml-3 rounded-full box-border',
            'group-hover:bg-darken-4',
            {
              'text-secondary': !checked && !disabled,
              'text-primary-75': checked && !disabled,
              'shadow-focus-inset': focused,
              'border-transparent text-disabled cursor-default': disabled
            }
          )}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: `disabled` isn't a property of div, but this may serve a purpose
          disabled={disabled}
        >
          {checked
            ? cloneElement(checkedIcon, {
                ...checkedIcon.props,
                accessibility: 'decorative',
                className: cx(
                  checkedIcon.props && checkedIcon.props.className
                    ? checkedIcon.props.className
                    : 'p-2'
                )
              })
            : cloneElement(uncheckedIcon, {
                ...uncheckedIcon.props,
                accessibility: 'decorative',
                className: cx(
                  uncheckedIcon.props && uncheckedIcon.props.className
                    ? uncheckedIcon.props.className
                    : 'p-2'
                )
              })}
          <input
            ref={ref}
            type={type}
            onChange={onChange}
            className='absolute left-0 w-10 h-10 opacity-0 cursor-pointer'
            data-testid={`${uniqueTestId}-input`}
            checked={checked}
            disabled={disabled}
            id={id}
            aria-label={
              label && hideLabel && typeof label === 'string'
                ? label
                : undefined
            }
            aria-labelledby={ariaLabelledBy ? ariaLabelledBy : undefined}
            {...focusHandlers}
            {...restProps}
          />
        </div>
        {label && (
          <div
            className={cx(
              'flex',
              { 'flex-row w-full justify-between': !!cta && !cta?.stacked },
              { 'flex-col items-start': !!cta && cta?.stacked }
            )}
          >
            <Label
              htmlFor={id}
              disabled={disabled}
              testId={`${uniqueTestId}-label`}
              className={cx(
                'font-normal',
                {
                  'sr-only': hideLabel
                },
                {
                  'pb-0 mb-0': cta && cta.stacked
                }
              )}
            >
              <div>
                {label}
                {subLabel && (
                  <div
                    className={cx('type-subhead', {
                      'text-secondary': !disabled,
                      'text-disabled': disabled
                    })}
                  >
                    {subLabel}
                  </div>
                )}
              </div>
            </Label>
            {cta && (
              <Button
                as='a'
                data-toast-track-id={cta['data-toast-track-id']}
                className={cx('min-w-max', { 'my-0.5 ml-3': !cta.stacked })}
                href={cta.href}
                variant='text-link'
                size='sm'
              >
                {cta.label}
              </Button>
            )}
          </div>
        )}
      </div>
    )
  }
)

CtaSelectionInput.displayName = 'CtaSelectionInput'
