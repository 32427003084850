import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEmployee, useUser } from '@local/contexts'
import { BANK_ACCOUNTS_QUERY_KEY } from '../../../../../api-documents'

// This name is matching the endpoint class definitions
export type ChangeAmountDepositRulesRequest = {
  depositRules: ChangePercentDepositRuleRequest[]
}

type ChangePercentDepositRuleRequest = {
  bankAccountId: string
  percentage: string
}

export const useDirectDepositSplitByPercentMutation = (
  onSuccess?: VoidFunction,
  onReauthenticationRequired?: VoidFunction,
  onError?: VoidFunction
) => {
  const queryClient = useQueryClient()

  const client = useUser().client
  const employeeId = useEmployee().id

  return useMutation({
    mutationFn: (props: ChangeAmountDepositRulesRequest) =>
      fetch(`/${client}/employees/${employeeId}/directDeposit/percent`, {
        method: 'PUT',
        headers: {
          Accept: 'application/com.toasttab.payroll.v1+json',
          'Content-Type': 'application/com.toasttab.payroll.v1+json',
          'Cache-Control': 'no-store'
        },
        body: JSON.stringify({ ...props })
      }).then((response) => {
        if (!response.ok) {
          response.json().then((responseJson) => {
            if (
              responseJson.errors.some(
                (error: string) => error === 'Reauthentication required'
              )
            ) {
              window.location.reload()
            } else {
              onError && onError()
            }
          })
        } else {
          onSuccess && onSuccess()
          queryClient.invalidateQueries({
            queryKey: [BANK_ACCOUNTS_QUERY_KEY, client, employeeId]
          })
        }
      })
  })
}
