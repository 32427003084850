import { useMutation } from '@tanstack/react-query'

export type SendDownloadAppLinkMutationProps = {
  phoneNumber: string
}

export const useSendDownloadAppLinkMutation = (
  onSuccess?: VoidFunction,
  onError?: VoidFunction
) => {
  return useMutation({
    mutationFn: (props: SendDownloadAppLinkMutationProps) =>
      fetch(`/sms/myToastDownload`, {
        method: 'POST',
        headers: {
          Accept: 'application/com.toasttab.payroll.v1+json',
          'Content-Type': 'application/com.toasttab.payroll.v1+json',
          'Cache-Control': 'no-store'
        },
        body: JSON.stringify({ phoneNumber: props.phoneNumber })
      })
        .catch(onError)
        .then(() => {
          onSuccess && onSuccess()
        })
  })
}
