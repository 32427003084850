import * as React from 'react'
import { trackSendDirectDepositInfoRequestModal } from '@local/track'
import { useEmployee, useUser } from '@local/hooks'
import { DirectDepositInfoRequestOption } from './SendDirectDepositInfoRequestModal'
import { isValidEmail as isValidEmailFormat } from '@toasttab/email-validation'
import { RadioButtonProps } from '@toasttab/buffet-pui-radio-group'
import { CtaRadioButtonProps } from '@local/cta-radio-button'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { t, Trans } from '@local/translations'

export const useEmailOption = (
  selectedOption: DirectDepositInfoRequestOption,
  isEmailValid: boolean,
  employeeEmail?: string | null
):
  | (Pick<CtaRadioButtonProps, 'value' | 'checked' | 'disabled' | 'cta'> & {
      label: React.ReactNode
    })
  | null => {
  const screenSize = useScreenSize()
  const client = useUser().client
  const employee = useEmployee()

  return !!employeeEmail
    ? {
        ...trackSendDirectDepositInfoRequestModal('option-email'),
        value: 'email',
        label: (
          <Trans
            i18nKey={
              isEmailValid
                ? 'direct-deposit-modal.option.email'
                : 'direct-deposit-modal.option.email-invalid'
            }
            values={{
              employeeFirstName: employee.firstName,
              employeeEmail: employeeEmail
            }}
            components={[<strong key='employee-email' />]}
          />
        ),
        cta: isEmailValid
          ? {
              ...trackSendDirectDepositInfoRequestModal('email-update'),
              label: t('direct-deposit-modal.update-email'),
              href: `/${client}/employees/${employee.id}/account`,
              stacked: screenSize <= ScreenSize.SM
            }
          : undefined,
        checked: selectedOption === 'email',
        disabled: !isEmailValid
      }
    : null
}

export const useManualOption = (
  selectedOption: DirectDepositInfoRequestOption
): Pick<RadioButtonProps, 'value' | 'checked' | 'disabled'> & {
  label: React.ReactNode
} => {
  return {
    ...trackSendDirectDepositInfoRequestModal('option-manual'),
    value: 'manual',
    label: t('direct-deposit-modal.option.manual'),
    checked: selectedOption === 'manual'
  }
}

export const isValidEmail = (email?: string | null) => {
  return (
    !!email &&
    isValidEmailFormat(email) &&
    !email.endsWith('@example.com') &&
    !email.endsWith('@test.com')
  )
}
