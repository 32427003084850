import { useEmployee, useUser } from '@local/contexts'
import { useQuery } from '@tanstack/react-query'

/**
 * Query to retrieve the plaid token from the server
 * @param enabled whether to enable this query...disabled when a token has already been retrieved, so
 * we don't try to get a new one which causes the plaid modal to refresh
 */
export function usePlaidLinkTokenQuery(enabled: boolean) {
  const client = useUser().client
  const employeeId = useEmployee().id

  return useQuery<PlaidLinkTokenQueryResponse>({
    queryKey: ['plaid-link-token', client, employeeId],
    queryFn: () =>
      fetch(`/${client}/employees/${employeeId}/plaidLinkToken`, {
        method: 'POST',
        headers: {
          Accept: 'application/com.toasttab.payroll.v1+json',
          'Content-Type': 'application/com.toasttab.payroll.v1+json',
          'Cache-Control': 'no-store'
        }
      }).then((res) => res.json()),
    enabled: enabled
  })
}

type PlaidLinkTokenQueryResponse = {
  token: string
  error?: {
    message: string
  }
}
