import * as React from 'react'

import { DividingLine, Panel } from '@toasttab/buffet-pui-config-templates'
import { Button } from '@toasttab/buffet-pui-buttons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { useEmployee, useIsViewingSelf } from '@local/hooks'
import { ClickableSection } from '@local/clickable-section'
import { PaymentMethodSelectedAlert } from './paymentMethodSelectedAlert'

import { PaymentMethodSelector } from './paymentMethodSelector'
import { usePaymentMethodClickableSectionProps } from './paymentMethodClickableSection'
import {
  BankAccountsSection,
  PlaidModal,
  useDirectDepositButtonProps
} from '../directDeposit'
import { DirectDepositSplitModal } from '../directDeposit'
import { t } from '@local/translations'
export const PaymentMethodPanel: React.FunctionComponent<TestIdentifiable> = ({
  testId = 'payment-method-panel'
}) => {
  const [isPlaidModalOpen, setIsPlaidModalOpen] = React.useState<boolean>(false)
  const [isSplitDepositModalOpen, setIsSplitDepositModalOpen] =
    React.useState<boolean>(false)

  const isViewingSelf = useIsViewingSelf()
  const employee = useEmployee()

  const subtitle = isViewingSelf
    ? t('payment-method-page.subtitle.self')
    : t('payment-method-page.subtitle', {
        employeeFirstName: employee.firstName
      })

  const clickableSectionProps = usePaymentMethodClickableSectionProps()

  const actionButtonProps = useDirectDepositButtonProps(
    () => setIsPlaidModalOpen(true),
    () => setIsSplitDepositModalOpen(true)
  )

  return (
    <>
      {isPlaidModalOpen && (
        <PlaidModal
          onClose={() => setIsPlaidModalOpen(false)}
          openSplitDepositModal={() => setIsSplitDepositModalOpen(true)}
        />
      )}
      {isSplitDepositModalOpen && (
        <DirectDepositSplitModal
          onClose={() => setIsSplitDepositModalOpen(false)}
        />
      )}
      <Panel
        testId={testId}
        title={t('payment-method-page.title')}
        subtitle={subtitle}
      >
        <div className='grid gap-y-4'>
          <PaymentMethodSelectedAlert />
          <PaymentMethodSelector
            openPlaidModal={() => setIsPlaidModalOpen(true)}
          />
          <BankAccountsSection />
          {clickableSectionProps && (
            <div>
              <DividingLine />
              <ClickableSection {...clickableSectionProps} />
            </div>
          )}
          {actionButtonProps && (
            <div className='flex justify-end mt-0 md:mt-2 xxl:mt-4'>
              <Button
                className='max-w-fit'
                variant='secondary'
                {...actionButtonProps}
              />
            </div>
          )}
        </div>
      </Panel>
    </>
  )
}
