import * as React from 'react'
import { ToastUser } from '@toasttab/ec-banquet-props'

export type UserContextUser = Pick<
  ToastUser,
  | 'client'
  | 'employeeUuid'
  | 'isPureUserHRPlusOrAbove'
  | 'isUserHrPlusOrDerivative'
  | 'isUserManager'
  | 'isUserSuperAdmin'
  | 'isUserSuperAdminLight'
>

export type UserContextValue = UserContextUser | null

const UserContext: React.Context<UserContextValue> =
  React.createContext<UserContextValue>(null)

export const UserContextProvider: React.FunctionComponent<{
  value: UserContextValue
  children: React.ReactNode
}> = ({ value, children }) => {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => {
  const userContext = React.useContext(UserContext)
  if (userContext === null) {
    // todo, is this what i want to throw?
    throw new Error('NULL_USER')
  }
  return userContext
}
