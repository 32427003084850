import * as React from 'react'
import { trackDownloadApp } from '@local/track'
import { Trans } from '@local/translations'

export const SuttonDisclaimer: React.FunctionComponent = () => {
  return (
    <div className='mt-6 px-6 text-center type-caption text-gray-75'>
      <Trans
        i18nKey={'pay-card-disclaimer.sutton-bank'}
        components={[
          <a
            {...trackDownloadApp('sutton-disclaimer')}
            href='https://pos.toasttab.com/products/toast-pay-card-and-pay-out/#availability'
            target='_blank'
            rel='noopener noreferrer'
          />
        ]}
      />
    </div>
  )
}
