import * as React from 'react'
import { t } from '@local/translations'

export const DownloadSteps: React.FunctionComponent = () => {
  return (
    <>
      <div className='my-4'>{t('download-app-modal.download-steps')}</div>
      <ol className='number-circle-list'>
        <li className='number-circle-list--list-item'>
          {t('download-app-modal.download-step-1')}
        </li>
        <li className='number-circle-list--list-item'>
          {t('download-app-modal.download-step-2')}
        </li>
        <li className='number-circle-list--list-item'>
          {t('download-app-modal.download-step-3')}
        </li>
      </ol>
    </>
  )
}
