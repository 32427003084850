import { useQuery } from '@tanstack/react-query'

export const BANK_ACCOUNTS_QUERY_KEY = 'bank-accounts'

export function useBankAccountsQuery(client: string, employeeId: string) {
  return useQuery<BankAccount[]>({
    queryKey: [BANK_ACCOUNTS_QUERY_KEY, client, employeeId],
    queryFn: () =>
      fetch(`/${client}/employees/${employeeId}/bankAccounts`).then((res) =>
        res.json()
      )
  })
}

export type BankAccount = {
  id: string
  accountType: BankAccountType
  bankName: string
  accountName: string
  accountNumber?: string
  routingNumber?: string
  percentage: number
  amount: number
  sequence: number
  createdBy?: string
}

export type BankAccountType = 'checking' | 'saving' | 'moneymarket' | 'paycard'
