import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEmployee, useUser } from '@local/contexts'
import { useRoutedFromOnboardingChecklist } from '@local/hooks'
import {
  BANK_ACCOUNTS_QUERY_KEY,
  PAYMENT_METHOD_QUERY_KEY
} from '../../../../../api-documents'

export type DeleteBankAccountMutationProps = {
  id: string
}

export const useDeleteBankAccountMutation = (
  onSuccess?: VoidFunction,
  onError?: VoidFunction
) => {
  const queryClient = useQueryClient()

  const user = useUser()
  const employee = useEmployee()
  const isFromOnboardingChecklist = useRoutedFromOnboardingChecklist()

  return useMutation({
    mutationFn: (props: DeleteBankAccountMutationProps) =>
      fetch(
        `/${user.client}/employees/${employee.id}/bankAccounts/${props.id}?onboardingChecklist=${isFromOnboardingChecklist}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/com.toasttab.payroll.v1+json',
            'Content-Type': 'application/com.toasttab.payroll.v1+json',
            'Cache-Control': 'no-store'
          }
        }
      )
        .catch(() => {
          onError && onError()
        })
        .then((response) => {
          if (!!response && !response.ok) {
            response.json().then((responseJson) => {
              if (
                responseJson.errors.some(
                  (error: string) => error === 'Reauthentication required'
                )
              ) {
                window.location.reload()
              } else {
                onError && onError()
              }
            })
          } else {
            onSuccess && onSuccess()
            queryClient.invalidateQueries({
              queryKey: [BANK_ACCOUNTS_QUERY_KEY, user.client, employee.id]
            })
            queryClient.invalidateQueries({
              queryKey: [PAYMENT_METHOD_QUERY_KEY, user.client, employee.id]
            })
          }
        })
  })
}
