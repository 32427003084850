import {
  useIsPayCardEnrollmentEnabled,
  useIsDirectDepositSelected,
  useIsPayCardSelected,
  useIsViewingSelf
} from '@local/hooks'
import { useEmployee } from '@local/contexts'
import { t } from '@local/translations'

export const usePaymentMethodSelectedAlertProps = () => {
  const payCardSelected = useIsPayCardSelected()
  const directDepositSelected = useIsDirectDepositSelected()

  if (payCardSelected) {
    return usePayCardAlertProps
  }

  if (directDepositSelected) {
    return useDirectDepositAlertProps
  }

  return usePaperCheckAlertProps
}

export const usePayCardAlertProps = () => {
  const employee = useEmployee()
  const isViewingSelf = useIsViewingSelf()

  return {
    title: t(`pay-card-selected${isViewingSelf ? '.self' : ''}`, {
      employeeFirstName: employee.firstName
    })
  }
}

export const useDirectDepositAlertProps = () => {
  const employee = useEmployee()
  const isViewingSelf = useIsViewingSelf()
  return {
    title: isViewingSelf
      ? t('direct-deposit-selected.self')
      : t('direct-deposit-selected', {
          employeeFirstName: employee.firstName
        })
  }
}

export const usePaperCheckAlertProps = () => {
  const employee = useEmployee()
  const isViewingSelf = useIsViewingSelf()
  const isPayCardEnrollmentEnabled = useIsPayCardEnrollmentEnabled()

  return {
    title: t(`paper-check-selected.title${isViewingSelf ? '.self' : ''}`, {
      employeeFirstName: employee.firstName
    }),
    children: t(
      `paper-check-selected${
        isViewingSelf
          ? isPayCardEnrollmentEnabled
            ? '.self.pay-card-available'
            : '.self'
          : ''
      }`
    )
  }
}
