import * as React from 'react'
import { FieldArray } from 'formik'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useEmployeeDirectDepositBankAccounts } from '@local/contexts'
import { BankAccount } from '../../../../api-documents'

type DirectDepositSplitTableProps = {
  columns: ColumnDef<BankAccount, any>[]
} & TestIdentifiable

export const DirectDepositSplitTable: React.FunctionComponent<
  DirectDepositSplitTableProps
> = ({ testId = 'direct-deposit-split-modal-table', columns }) => {
  const bankAccounts = useEmployeeDirectDepositBankAccounts()

  const table = useReactTable({
    data: bankAccounts,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Table variant='plain' data-testid={testId}>
      <Head>
        {table.getHeaderGroups().map((headerGroup) => (
          <Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <HeadingCell key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </HeadingCell>
            ))}
          </Row>
        ))}
      </Head>
      <Body>
        <FieldArray
          name='bankAccounts'
          render={() => (
            <>
              {table.getRowModel().rows.map((row) => (
                <Row key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Cell
                      key={cell.id}
                      className='w-auto md:w-1/3 align-middle'
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Cell>
                  ))}
                </Row>
              ))}
            </>
          )}
        />
      </Body>
    </Table>
  )
}
