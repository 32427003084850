import * as yup from 'yup'
import { BankAccount } from '../../../../api-documents'
import { useDirectDepositAmountSplitSchema } from '../amount-split'
import { useDirectDepositPercentSplitSchema } from '../percent-split'

export type DirectDepositSplitSchema = {
  isSplitByAmount: boolean
  bankAccounts: BankAccount[]
}

export const useDirectDepositSplitSchema = () => {
  const amountSplitSchemaFunction = useDirectDepositAmountSplitSchema()
  const percentSplitSchemaFunction = useDirectDepositPercentSplitSchema()

  return yup.object({
    bankAccounts: yup
      .array()
      .required()
      .when('isSplitByAmount', {
        is: true,
        then: amountSplitSchemaFunction
      })
      .when('isSplitByAmount', {
        is: false,
        then: percentSplitSchemaFunction
      })
  })
}
