import { graphql } from '@local/grouter'

export const EmployeeForDownloadApp = graphql(`
  query EmployeeForDownloadApp($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      user {
        email
        name {
          formatted
        }
      }
      contact {
        telephoneNumber {
          telephoneNumber
        }
      }
    }
  }
`)
