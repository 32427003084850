import {
  useIsPaperCheckSelected,
  useIsPayCardSelected,
  useUser
} from '@local/hooks'
import { ClickableSectionProps } from '@local/clickable-section'
import { trackClickableSection } from '@local/track'
import { t } from '@local/translations'

export const usePaymentMethodClickableSectionProps =
  (): ClickableSectionProps | null => {
    const paperCheckSettingsProps = usePaperCheckSettingsProps()
    const payCardSettingsProps = usePayCardSettingsProps()
    const payCardFaqProps = usePayCardFaqProps()
    return paperCheckSettingsProps || payCardSettingsProps || payCardFaqProps
  }

export const usePaperCheckSettingsProps = (): ClickableSectionProps | null => {
  const showPaperCheckSettingsLink = useShowPaperCheckSettingsLink()
  const client = useUser().client
  return showPaperCheckSettingsLink
    ? {
        ...trackClickableSection('paper-check-settings'),
        title: t('clickable-section.paper-check-settings.title'),
        subtitle: t('clickable-section.paper-check-settings.description'),
        'aria-label': t('clickable-section.paper-check-settings.aria-label'),
        href: `/mvc/${client}/Company/Setup/PaycheckDelivery`,
        variant: 'internal'
      }
    : null
}

export const useShowPaperCheckSettingsLink = (): boolean => {
  const isPaperCheckSelected = useIsPaperCheckSelected()
  const canViewPaperCheckSettings = useCanViewPaperCheckSettings()
  return isPaperCheckSelected && canViewPaperCheckSettings
}

export const useCanViewPaperCheckSettings = (): boolean => {
  const user = useUser()
  return (
    user.isPureUserHRPlusOrAbove ||
    user.isUserHrPlusOrDerivative ||
    user.isUserManager ||
    user.isUserSuperAdmin ||
    user.isUserSuperAdminLight
  )
}

export const usePayCardSettingsProps = (): ClickableSectionProps | null => {
  const showPayCardSettingsLink = useShowPayCardSettingsLink()
  const client = useUser().client
  return showPayCardSettingsLink
    ? {
        ...trackClickableSection('pay-card-settings'),
        title: t('clickable-section.pay-card-settings.title'),
        subtitle: t('clickable-section.pay-card-settings.description'),
        'aria-label': t('clickable-section.pay-card-settings.aria-label'),
        href: `/mvc/${client}/Company/Setup/PaycardSettings`,
        variant: 'internal'
      }
    : null
}

export const useShowPayCardSettingsLink = (): boolean => {
  const isPayCardSelected = useIsPayCardSelected()
  const canViewPayCardSettings = useCanViewPayCardSettings()
  return isPayCardSelected && canViewPayCardSettings
}

export const useCanViewPayCardSettings = (): boolean => {
  const user = useUser()
  return (
    user.isPureUserHRPlusOrAbove ||
    user.isUserHrPlusOrDerivative ||
    user.isUserManager ||
    user.isUserSuperAdmin ||
    user.isUserSuperAdminLight
  )
}

export const usePayCardFaqProps = (): ClickableSectionProps | null => {
  const showPayCardFaqLink = useShowPayCardFaqLink()
  return showPayCardFaqLink
    ? {
        ...trackClickableSection('pay-card-faq'),
        title: t('clickable-section.pay-card-faq.title'),
        subtitle: t('clickable-section.pay-card-faq.description'),
        'aria-label': t('clickable-section.pay-card-faq.aria-label'),
        href: 'https://central.toasttab.com/s/article/Toast-Payroll-Use-and-Troubleshoot-Toast-Pay-Card-Employee-Guide',
        variant: 'external'
      }
    : null
}

export const useShowPayCardFaqLink = (): boolean => {
  const isPayCardSelected = useIsPayCardSelected()
  const canViewPayCardSettings = useCanViewPayCardSettings()
  return isPayCardSelected && !canViewPayCardSettings
}
