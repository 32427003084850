import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { PayCardAdBanner, PayCardDisclaimer } from './payCard'
import { DirectDepositDisclaimer } from './directDeposit'
import { PaymentMethodPanel } from './payment-method-panel'
import { useReauthProps } from './utils'
import { AddBankAccountPanel } from '../add-bank-account-panel'
import { ReauthContainer } from '@toasttab/ec-session'

export const PaymentMethodPage: React.FunctionComponent<TestIdentifiable> = ({
  testId = 'payment-method-page'
}) => {
  const reauthProps = useReauthProps()
  // React.Suspense is required to resolve the useFeature hook promise. Once this is no longer behind a feature flag, suspense can be removed
  return (
    <React.Suspense>
      <ReauthContainer {...reauthProps}>
        <div className='w-full' data-testid={testId}>
          <PayCardAdBanner />
          <PaymentMethodPanel />
          <AddBankAccountPanel />
          <PayCardDisclaimer />
          <DirectDepositDisclaimer />
        </div>
      </ReauthContainer>
    </React.Suspense>
  )
}
