import { DirectDepositSplitSchema } from '../schema'
import { ChangeAmountDepositRulesRequest } from './api-documents'

export const convertFormSubmissionToPercentageRequest = (
  input: DirectDepositSplitSchema
): ChangeAmountDepositRulesRequest => {
  return {
    depositRules: input.bankAccounts.map((bankAccount) => {
      return {
        bankAccountId: bankAccount.id,
        percentage: bankAccount.percentage.toString()
      }
    })
  }
}
