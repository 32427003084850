import { graphql } from '@local/grouter'

export const SendUpdateEmployeePaymentMethodEmail = graphql(`
  mutation SendUpdateEmployeePaymentMethodEmail($employeeId: ID!) {
    sendUpdateEmployeePaymentMethodEmail(employeeId: $employeeId) {
      ... on EmployeePaymentMethodEmailResponse {
        __typename
      }
    }
  }
`)
