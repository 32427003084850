import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { usePaymentMethodSelectedAlertProps } from './utils'

export const PaymentMethodSelectedAlert: React.FunctionComponent<
  TestIdentifiable
> = ({ testId = 'payment-method-selected-alert' }) => {
  const alertProps = usePaymentMethodSelectedAlertProps()()
  return (
    <Alert
      testId={testId}
      className='w-full'
      variant='success'
      {...alertProps}
    />
  )
}
