import * as React from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { FieldArray, useFormikContext } from 'formik'
import { DirectDepositSplitSchema } from '../schema'
import { DirectDepositSplitTableWithDragAndDrop } from './DirectDepositSplitTableWithDragAndDrop'
import { useAmountSplitModalTableColumns } from './AmountSplitModalTableColumns'

export const AmountSplitTable: React.FunctionComponent = () => {
  const columns = useAmountSplitModalTableColumns()

  const { values } = useFormikContext<DirectDepositSplitSchema>()

  const onDragEnd = (
    result: DropResult,
    handleFormikMove: (from: number, to: number) => void
  ) => {
    if (!result.destination) {
      return
    }
    const startIndex = result.source.index
    const endIndex = result.destination.index

    handleFormikMove(startIndex, endIndex)
  }

  return (
    <FieldArray
      name='bankAccounts'
      render={(arrayHelpers) => (
        <DragDropContext
          onDragEnd={(result) => {
            onDragEnd(result, arrayHelpers.move)
          }}
        >
          <DirectDepositSplitTableWithDragAndDrop
            columns={columns}
            data={values.bankAccounts}
          />
        </DragDropContext>
      )}
    />
  )
}
