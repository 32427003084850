import * as React from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'

import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { useEmployeeDirectDepositBankAccounts } from '@local/contexts'

import { useBankAccountsTableColumns } from './BankAccountsTableColumns'

/**
 * BankAccountsTable component
 * a table displaying the direct deposit bank account information
 * supports deleting a direct deposit bank account
 */
export const BankAccountsTable: React.FunctionComponent<TestIdentifiable> = ({
  testId
}) => {
  const bankAccounts = useEmployeeDirectDepositBankAccounts()
  const data = React.useMemo(() => bankAccounts, [bankAccounts])
  const columns = useBankAccountsTableColumns()
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Table data-testid={testId} variant='plain'>
      <Head>
        {table.getHeaderGroups().map((headerGroup) => (
          <Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <HeadingCell key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </HeadingCell>
            ))}
          </Row>
        ))}
      </Head>
      <Body>
        {table.getRowModel().rows.map((row) => (
          <Row key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Cell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Cell>
            ))}
          </Row>
        ))}
      </Body>
    </Table>
  )
}
