import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { BankAccountsTable } from './BankAccountsTable'
import { useEmployeeDirectDepositBankAccounts } from '@local/contexts'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { useIsViewingSelf } from '@local/hooks'
import { t } from '@local/translations'

/**
 * BankAccountsSection component
 * the section that displays the direct deposit bank account information
 * in a tabular format
 */
export const BankAccountsSection: React.FunctionComponent<TestIdentifiable> = ({
  testId = 'bank-accounts-section'
}) => {
  const bankAccountsCount = useEmployeeDirectDepositBankAccounts().length
  const isViewingSelf = useIsViewingSelf()

  return !isViewingSelf || bankAccountsCount < 1 ? null : (
    <div data-testid={testId} className='gap-y-0'>
      <DividingLine />
      <div className='font-bold mt-1.5'>
        {t('direct-deposit-accounts.heading')}
      </div>
      <div className='text-secondary mt-2'>
        {t('direct-deposit-accounts.subheading', { count: bankAccountsCount })}
      </div>
      <div className='mt-6 mb-2'>
        <BankAccountsTable />
      </div>
    </div>
  )
}
