import * as React from 'react'
import { ArrowForwardIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Image } from '@toasttab/buffet-pui-image'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { trackPayCardAd } from '@local/track'
import {
  PAYCARD_FILLED_IMAGE_URL,
  TWO_TILTED_PAYCARDS_IMAGE_URL
} from './images'
import { useShowPayCardAd } from './utils'
import { DownloadAppModal } from '../../downloadApp'
import { t } from '@local/translations'

export const PayCardAdBanner = ({
  testId = 'pay-card-ad-card'
}: TestIdentifiable) => {
  const showPayCardAd = useShowPayCardAd()

  const [isDownloadAppModalOpen, setIsDownloadAppModalOpen] =
    React.useState<boolean>(false)

  const mobileAdCard = (
    <div className='flex w-full px-4' data-testid={`${testId}-mobile`}>
      <Image
        testId={`${testId}-mobile-image`}
        alt={t('image-alt.pay-card')}
        className='w-2/5'
        fit='contain'
        src={TWO_TILTED_PAYCARDS_IMAGE_URL}
      />
      <div className='w-3/5 pl-4'>
        <div
          className='mb-4 type-headline-4'
          data-testid={`${testId}-mobile-header`}
        >
          {t('pay-card-ad.mobile.header')}
        </div>
        <Button
          {...trackPayCardAd('sign-up')}
          data-testid={`${testId}-mobile-button`}
          iconRight={<ChevronRightIcon accessibility='decorative' />}
          onClick={() => setIsDownloadAppModalOpen(true)}
          size='sm'
          variant='secondary'
        >
          {t('pay-card-ad.mobile.button')}
        </Button>
      </div>
    </div>
  )

  const desktopAdCard = (
    <CardContainer
      className='flex'
      noPadding
      verticalEdge='auto-md'
      data-testid={`${testId}-desktop`}
    >
      <Image
        testId={`${testId}-desktop-image`}
        alt={t('image-alt.pay-card')}
        className='rounded-l-md paycard-filled'
        src={PAYCARD_FILLED_IMAGE_URL}
      />
      <div className='flex justify-between w-full px-6 m-0'>
        <div className='ml-0 mr-3 my-auto'>
          <div
            className='font-bold type-headline-5'
            data-testid={`${testId}-desktop-header`}
          >
            {t('pay-card-ad.desktop.header')}
          </div>
          <div
            className='text-secondary'
            data-testid={`${testId}-desktop-subheader`}
          >
            {t('pay-card-ad.desktop.subheader')}
          </div>
        </div>
        <Button
          className='w-auto mx-3 my-auto flex-shrink-0 flex-grow px-5'
          variant='primary'
          onClick={() => setIsDownloadAppModalOpen(true)}
          iconRight={<ArrowForwardIcon accessibility='decorative' />}
          data-testid={`${testId}-desktop-button`}
        >
          {t('pay-card-ad.desktop.button')}
        </Button>
      </div>
    </CardContainer>
  )

  // todo should the "mobile" view be shown on small screens or mobile devices?
  return showPayCardAd ? (
    <>
      {isDownloadAppModalOpen && (
        <DownloadAppModal onClose={() => setIsDownloadAppModalOpen(false)} />
      )}
      <section className='mb-4' data-testid={testId}>
        <div className='md:hidden'>{mobileAdCard}</div>
        <div className='hidden md:block'>{desktopAdCard}</div>
      </section>
    </>
  ) : null
}
