import * as React from 'react'
import {
  ErrorCallToActionSection,
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading
} from '@toasttab/buffet-pui-error-pages'
import { TabletTeamIllustration } from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export type ErrorPageMissingInfoProps = {
  heading: string
  subheading: string
  href?: string
  buttonLabel: string
  'data-toast-track-id': string
} & TestIdentifiable

/**
 * Error page component for communicating missing employee info
 */
export const ErrorPageMissingInfo: React.FunctionComponent<
  ErrorPageMissingInfoProps
> = ({ testId, heading, subheading, href, buttonLabel, ...rest }) => {
  return (
    <ErrorContainer>
      <div className='flex'>
        <div
          className='hidden md:block visible mx-auto'
          style={{ maxWidth: 600 }}
        >
          <TabletTeamIllustration />
        </div>
        <div
          className='block md:hidden visible mx-auto'
          style={{ maxWidth: 250 }}
        >
          <TabletTeamIllustration resizeToContainer />
        </div>
      </div>
      <div>
        <ErrorHeading>{heading}</ErrorHeading>
        <ErrorSubheading>{subheading}</ErrorSubheading>
      </div>
      {href && (
        <ErrorCallToActionSection spacing='flex flex-col items-center space-y-4 md:space-y-0 md:space-x-4'>
          <Button
            testId={`${testId}-button`}
            data-toast-track-id={rest['data-toast-track-id']}
            as='a'
            href={href}
            iconRight={<ArrowForwardIcon accessibility='decorative' />}
            variant='primary'
          >
            {buttonLabel}
          </Button>
        </ErrorCallToActionSection>
      )}
    </ErrorContainer>
  )
}
