import {
  EmployeeForAppContextQuery,
  EmployeePayCardAccessQuery,
  PayCardEmployeeAccessLevel
} from '@local/grouter'

export const isEmployeeMissingCriticalInfo = (
  employee?: EmployeeForAppContextQuery
): boolean => {
  return (
    !employee?.findEmployeeById?.user?.email ||
    !employee?.findEmployeeById?.user?.username
  )
}

export const getEmployeeFirstName = (
  employee?: EmployeeForAppContextQuery
): string | undefined | null => {
  return (
    employee?.findEmployeeById?.user?.name?.first ||
    employee?.findEmployeeById?.user?.name?.chosen
  )
}

export const getEmployeePayCardAccessLevel = (
  employeePayCardAccessData?: EmployeePayCardAccessQuery
): PayCardEmployeeAccessLevel => {
  return employeePayCardAccessData?.payCardEmployeeAccess?.accessLevel || 'NONE'
}
