import * as React from 'react'
import { isMobile } from 'react-device-detect'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { MobileDownloadAppModal } from './mobile'
import { SendDownloadAppLinkModal } from './desktop'

export type DownloadAppModalProps = {
  onClose: () => void
} & TestIdentifiable

export const DownloadAppModal: React.FunctionComponent<
  DownloadAppModalProps
> = (props) => {
  return isMobile ? (
    <MobileDownloadAppModal {...props} />
  ) : (
    <SendDownloadAppLinkModal {...props} />
  )
}
