import { graphql } from '@local/grouter'

export const EmployeeForDirectDeposit = graphql(`
  query EmployeeForDirectDeposit($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      user {
        email
      }
    }
  }
`)
