import {
  useEmployeeHasActivePayCard,
  useIsPayCardEnrollmentEnabled,
  useIsPayCardSelected,
  useIsViewingSelf
} from '@local/hooks'

export const useShowPayCardDisclaimer = (): boolean => {
  const isViewingSelf = useIsViewingSelf()
  const isPayCardMethod = useIsPayCardEnrollmentEnabled()
  const employeeHasActivePayCard = useEmployeeHasActivePayCard()
  const isPayCardSelected = useIsPayCardSelected()

  return (
    isViewingSelf &&
    isPayCardMethod &&
    (employeeHasActivePayCard ? isPayCardSelected : !isPayCardSelected)
  )
}
