import { useQuery } from '@tanstack/react-query'

export const PAYMENT_METHOD_QUERY_KEY = 'payment-method'

export function usePaymentMethodQuery(client: string, employeeId: string) {
  return useQuery<PaymentMethodQueryResponse>({
    queryKey: [PAYMENT_METHOD_QUERY_KEY, client, employeeId],
    queryFn: () =>
      fetch(`/${client}/employees/${employeeId}/payMethod`).then((res) =>
        res.json()
      )
  })
}

export type PaymentMethodQueryResponse = {
  type: PaymentMethod
  obj: {
    id: {
      customerId: string
      employeeId: string
    }
    pendingPaymentMethod: PendingPaymentMethod
    splitAccounts: SplitAccounts[]
  }
}

export type PaymentMethod =
  | 'EmptyPaymentMethod'
  | 'PayByPaperCheck'
  | 'PayByDirectDepositPercentageSplit'
  | 'PayByDirectDepositAmountSplit'
  | 'PayByLegacyDirectDeposit'
  | 'PayByPayCard'

export type PendingPaymentMethod = 'direct_deposit' | 'not_pending'

export type SplitAccounts = {
  id: string
  amount: number
  percentage?: number
}
