import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { ConfirmationModal } from '@local/confirmation-modal'
import { trackPaperCheckConfirmation } from '@local/track'
import { useEmployee, useIsViewingSelf } from '@local/hooks'
import { t } from '@local/translations'

export type PaperCheckConfirmationModalProps = {
  isOpen: boolean
  onCancel: VoidFunction
  onConfirm: VoidFunction
} & TestIdentifiable

export const PaperCheckConfirmationModal: React.FunctionComponent<
  PaperCheckConfirmationModalProps
> = ({ testId, isOpen, onCancel, onConfirm }) => {
  const isViewingSelf = useIsViewingSelf()
  const employee = useEmployee()

  return isOpen ? (
    <ConfirmationModal
      testId={testId}
      heading={t('paper-check-confirmation.heading')}
      isOpen={isOpen}
      cancelButtonProps={{
        ...trackPaperCheckConfirmation('cancel'),
        text: t('paper-check-confirmation.button.cancel'),
        onClick: onCancel
      }}
      confirmButtonProps={{
        ...trackPaperCheckConfirmation('confirm'),
        text: t(
          `paper-check-confirmation.button.confirm${
            isViewingSelf ? '.self' : ''
          }`
        ),
        onClick: onConfirm
      }}
    >
      {t(`paper-check-confirmation${isViewingSelf ? '.self' : ''}`, {
        employeeFirstName: employee.firstName
      })}
    </ConfirmationModal>
  ) : null
}
