import * as React from 'react'
import cx from 'classnames'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { DragIndicatorIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { BankAccount } from '../../../../api-documents'

type DirectDepositSplitTableProps = {
  columns: ColumnDef<BankAccount, any>[]
  data: BankAccount[]
} & TestIdentifiable

export const DirectDepositSplitTableWithDragAndDrop: React.FunctionComponent<
  DirectDepositSplitTableProps
> = ({ testId = 'direct-deposit-split-modal-table', columns, data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Droppable droppableId='droppable'>
      {(dropProvided) => (
        <Table
          data-testid={testId}
          valign='middle'
          variant='plain'
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
        >
          <Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Row key={headerGroup.id}>
                <HeadingCell noMinWidth={true} className='pl-0 pr-0' />
                {headerGroup.headers.map((header) => (
                  <HeadingCell key={header.id} className='pl-2'>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </HeadingCell>
                ))}
              </Row>
            ))}
          </Head>
          <Body>
            {table.getRowModel().rows.map((row) => (
              <Draggable key={row.id} draggableId={row.id} index={row.index}>
                {(dragProvided, dragSnapshot) => (
                  <Row
                    key={row.id}
                    ref={dragProvided.innerRef}
                    {...{
                      ...dragProvided.draggableProps,
                      style: {
                        ...dragProvided.draggableProps.style,
                        // react-beautiful-dnd dragging offset issue with fixed or absolute position
                        left: undefined,
                        top: undefined
                      }
                    }}
                    className={cx(dragSnapshot.isDragging && 'shadow-xl')}
                  >
                    <Cell
                      className='cursor-move pl-0 pr-0'
                      {...dragProvided.dragHandleProps}
                    >
                      <DragIndicatorIcon aria-label='drag' />
                    </Cell>
                    {row.getVisibleCells().map((cell) => (
                      <Cell
                        key={`row-${row.id}-cell-${cell.id}`}
                        className='w-auto md:w-1/3 last:pr-0 pl-2'
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Cell>
                    ))}
                  </Row>
                )}
              </Draggable>
            ))}
          </Body>
        </Table>
      )}
    </Droppable>
  )
}
