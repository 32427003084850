import * as React from 'react'
import { ChevronRightIcon, LaunchIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export type ClickableSectionProps = {
  'data-toast-track-id'?: string
  title: string
  subtitle: string
  'aria-label': string
  href: string
  variant?: 'internal' | 'external'
} & TestIdentifiable

export const ClickableSection: React.FunctionComponent<
  ClickableSectionProps
> = ({
  title,
  subtitle,
  href,
  variant = 'internal',
  testId = 'payment-method-panel-clickable-area',
  ...rest
}) => {
  return (
    <div
      data-toast-track-id={rest['data-toast-track-id']}
      data-testid={testId}
      aria-label={rest['aria-label']}
      className='flex cursor-pointer pt-3.5'
      onClick={() => {
        if (variant === 'internal') {
          window.location.href = href
        } else {
          window.open(href, '_blank', 'noopener,noreferrer')
        }
      }}
    >
      <div className='flex flex-col grow gap-y-2'>
        <div className='type-large font-bold'>{title}</div>
        <div className='text-secondary font-normal'>{subtitle}</div>
      </div>
      <IconButton
        cropToIcon
        textClassName='text-gray-100'
        icon={
          variant === 'internal' ? (
            <ChevronRightIcon aria-label={rest['aria-label']} />
          ) : (
            <LaunchIcon aria-label={rest['aria-label']} />
          )
        }
      />
    </div>
  )
}
