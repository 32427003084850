import * as React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useNavigateToLegacyDirectDepositLink } from '@toasttab/ec-session'
import { useEmployee } from '@local/contexts'
import { t } from '@local/translations'

export type PlaidErrorModalProps = {
  institutionNotFound: boolean
  onClose: () => void
} & TestIdentifiable

export const PlaidErrorModal = ({
  institutionNotFound,
  onClose,
  testId = 'plaid-error-modal'
}: PlaidErrorModalProps) => {
  const navigateToLegacyDirectDepositLink =
    useNavigateToLegacyDirectDepositLink(useEmployee().id)

  return (
    <Modal isOpen={true} size='md' testId={`${testId}`}>
      <Modal.Header>
        {institutionNotFound
          ? t('plaid-error-modal.header.institution-not-found')
          : t('plaid-error-modal.header')}
      </Modal.Header>
      <Modal.Body>
        <div className='type-default text-secondary'>
          {t('plaid-error-modal.description')}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid={`${testId}-close-button`}
          onClick={onClose}
          variant='link'
        >
          {t('plaid-error-modal.secondary-button')}
        </Button>
        <Button
          data-testid={`${testId}-add-bank-account`}
          onClick={navigateToLegacyDirectDepositLink}
          variant='primary'
        >
          {t('plaid-error-modal.primary-button')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
