import {
  useEmployee,
  useIsPaperCheckSelected,
  useIsViewingSelf
} from '@local/hooks'

export const useShowBankAccountPanel = (): boolean => {
  const bankAccounts = useEmployee().bankAccounts.filter(
    (bankAccount) => bankAccount.accountType !== 'paycard'
  )
  const noBankAccounts = bankAccounts?.length === 0
  const isViewingSelf = useIsViewingSelf()
  const isPaperCheckSelected = useIsPaperCheckSelected()
  return noBankAccounts && isViewingSelf && isPaperCheckSelected
}
