import { useMutation } from '@tanstack/react-query'
import { isMobile } from 'react-device-detect'
import { useEmployee, useUser } from '@local/contexts'
import { useRoutedFromOnboardingChecklist } from '@local/hooks'

export type LogDownloadAppLinkSentMutationProps = {
  email?: string | null
  fullName?: string | null
  phoneNumber?: string | null
}

export const useLogDownloadAppLinkSentMutation = (
  onSuccess?: VoidFunction,
  onError?: VoidFunction
) => {
  const user = useUser()
  const employee = useEmployee()
  const isFromOnboardingChecklist = useRoutedFromOnboardingChecklist()

  return useMutation({
    mutationFn: (props: LogDownloadAppLinkSentMutationProps) =>
      fetch(`/employees/${employee.id}/log/downloadLinkSent`, {
        method: 'POST',
        headers: {
          Accept: 'application/com.toasttab.payroll.v1+json',
          'Content-Type': 'application/com.toasttab.payroll.v1+json',
          'Cache-Control': 'no-store'
        },
        body: JSON.stringify({
          companyCode: user.client,
          device: isMobile ? 'mobile' : 'desktop',
          email: props.email,
          fullName: props.fullName,
          onboardingChecklist: isFromOnboardingChecklist,
          phoneNumber: props.phoneNumber
        })
      })
        .catch(() => {
          onError && onError()
        })
        .then(() => {
          onSuccess && onSuccess()
        })
  })
}
