import * as React from 'react'
import { PayCardEmployeeAccessLevel } from '@local/grouter'
import {
  BankAccount,
  PaymentMethod,
  SplitAccounts
} from '../../../../src/app/api-documents'

export type EmployeeContextValue = {
  id: string
  firstName?: string
  paymentMethod?: PaymentMethod
  payCardAccessLevel?: PayCardEmployeeAccessLevel
  bankAccounts: BankAccount[]
  splitAccounts: SplitAccounts[]
} | null

const EmployeeContext: React.Context<EmployeeContextValue> =
  React.createContext<EmployeeContextValue>(null)

export const EmployeeContextProvider: React.FunctionComponent<{
  value: EmployeeContextValue
  children: React.ReactNode
}> = ({ value, children }) => {
  return (
    <EmployeeContext.Provider value={value}>
      {children}
    </EmployeeContext.Provider>
  )
}

export const useEmployee = () => {
  const employeeContext = React.useContext(EmployeeContext)
  if (employeeContext === null) {
    // todo, is this what i want to throw?
    throw new Error('NULL_EMPLOYEE')
  }
  return employeeContext
}

export const useIsPayCardSelected = (): boolean => {
  return useEmployee().paymentMethod === 'PayByPayCard'
}

export const useIsDirectDepositSelected = (): boolean => {
  const paymentMethod = useEmployee().paymentMethod
  return (
    paymentMethod === 'PayByLegacyDirectDeposit' ||
    paymentMethod === 'PayByDirectDepositAmountSplit' ||
    paymentMethod === 'PayByDirectDepositPercentageSplit'
  )
}

export const useIsPaperCheckSelected = (): boolean => {
  const paymentMethod = useEmployee().paymentMethod
  return (
    paymentMethod === 'EmptyPaymentMethod' ||
    paymentMethod === 'PayByPaperCheck'
  )
}

export const useEmployeeHasActivePayCard = (): boolean => {
  const employee = useEmployee()

  const bankAccountsWithoutPayCard = useEmployeeDirectDepositBankAccounts()

  return (
    (employee.payCardAccessLevel === 'PAYOUTS' ||
      employee.payCardAccessLevel === 'PAYCARD') &&
    employee.bankAccounts.length > bankAccountsWithoutPayCard.length
  )
}

export const useEmployeeDirectDepositBankAccounts = () => {
  const bankAccounts = useEmployee().bankAccounts
  return React.useMemo(
    () => bankAccounts.filter((acct) => acct.accountType !== 'paycard'),
    [bankAccounts]
  )
}
