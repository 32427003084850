import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useShowDirectDepositDisclaimer } from './utils'
import { t } from '@local/translations'

export const DirectDepositDisclaimer: React.FunctionComponent<
  TestIdentifiable
> = ({
  testId = 'payment-method-direct-deposit-disclaimer'
}: TestIdentifiable) => {
  const showDirectDepositDisclaimer = useShowDirectDepositDisclaimer()

  return showDirectDepositDisclaimer ? (
    <section className='pl-4 pr-4 mt-4 mb-16 md:mt-8' data-testid={testId}>
      <div className='mt-10 md:mt-5 type-caption text-gray-75'>
        {t('direct-deposit-disclaimer.1')}
      </div>
      <div className='mt-10 md:mt-5 type-caption text-gray-75'>
        {t('direct-deposit-disclaimer.2')}
      </div>
    </section>
  ) : null
}
