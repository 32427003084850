import {
  useEmployeeHasActivePayCard,
  useIsPayCardEnrollmentEnabled,
  useIsViewingSelf
} from '@local/hooks'

export const useShowPayCardAd = () => {
  const isViewingSelf = useIsViewingSelf()
  const isPayCardEnrollmentEnabled = useIsPayCardEnrollmentEnabled()
  const isEmployeeHasActivePayCard = useEmployeeHasActivePayCard()

  return (
    isViewingSelf && isPayCardEnrollmentEnabled && !isEmployeeHasActivePayCard
  )
}
