import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { DirectDepositSplitSchema } from './schema'
import {
  convertFormSubmissionToAmountRequest,
  useDirectDepositSplitByAmountMutation
} from './amount-split'
import {
  convertFormSubmissionToPercentageRequest,
  useDirectDepositSplitByPercentMutation
} from './percent-split'
import { t } from '@local/translations'

export const useSubmit = (
  onClose: VoidFunction
): ((input: DirectDepositSplitSchema) => void) => {
  const onSuccess = () => {
    showSuccessSnackBar(t('direct-deposit-split-modal.success'))
    onClose()
  }
  const onError = () => {
    showErrorSnackBar(t('direct-deposit-split-modal.error'))
    onClose()
  }

  const saveAmountBankAccountMutation = useDirectDepositSplitByAmountMutation(
    onSuccess,
    onError
  )

  const savePercentBankAccountMutation = useDirectDepositSplitByPercentMutation(
    onSuccess,
    onError
  )

  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()

  return (values) => {
    if (values.isSplitByAmount && values.bankAccounts.length > 0) {
      const fixedData = convertFormSubmissionToAmountRequest(values)
      if (!!fixedData) {
        saveAmountBankAccountMutation.mutate(fixedData)
      } else {
        onError()
      }
    } else if (values.bankAccounts.length > 0) {
      const fixedData = convertFormSubmissionToPercentageRequest(values)
      if (!!fixedData) {
        savePercentBankAccountMutation.mutate(fixedData)
      } else {
        onError()
      }
    }
  }
}
