import * as yup from 'yup'
import { t } from '@local/translations'

export const useDirectDepositAmountSplitSchema = () => {
  return (schema: any) =>
    schema.of(
      yup
        .object({
          amount: yup
            .number()
            .required(t('direct-deposit-split-modal.amount-required'))
            .min(0, t('direct-deposit-split-modal.amount-min-error'))
            .max(9.999e20, t('direct-deposit-split-modal.amount-max-error'))
        })
        .required()
    )
}
