import { useQuery } from '@tanstack/react-query'
import { useEmployee, useUser } from '@local/contexts'

const NEW_HIRE_NEXT_STEP_LINK_QUERY_KEY = 'new-hire-next-step-link'

export const useNewHireNextStepLink = () => {
  const employeeId = useEmployee().id
  const client = useUser().client

  return useQuery<string>({
    queryKey: [NEW_HIRE_NEXT_STEP_LINK_QUERY_KEY, client, employeeId],
    queryFn: () =>
      fetch(
        `/mvc/${client}/Employee/GetNextStepLink?employeeUuid=${employeeId}`
      ).then((res) => res.json())
  })
}
