import {
  track as ecTrack,
  useTracking as useEcTracking
} from '@toasttab/ec-track'

type DataToastTrackIdProps = {
  'data-toast-track-id': TrackId
}

type App = 'employee-payment-method'

type MissingEmployeeInfoTrackId = 'missing-employee-info'
type MissingEmployeeInfoActionTrackIds = 'new-hire-link'

export const trackMissingEmployeeInfo = (
  actionTrackId: MissingEmployeeInfoActionTrackIds
): DataToastTrackIdProps => {
  return track(`employee-payment-method.missing-employee-info.${actionTrackId}`)
}

type PayCardAdTrackId = 'pay-card-ad'
type PayCardAdTrackIds = 'sign-up'

export const trackPayCardAd = (
  actionTrackId: PayCardAdTrackIds
): DataToastTrackIdProps => {
  return track(`employee-payment-method.pay-card-ad.${actionTrackId}`)
}

type SelectorTrackId = 'selector'
type SelectorTrackIds = 'pay-card' | 'paper-check' | 'direct-deposit'

export const trackSelector = (
  actionTrackId: SelectorTrackIds
): DataToastTrackIdProps => {
  return track(`employee-payment-method.selector.${actionTrackId}`)
}

type ClickableSectionTrackId = 'clickable-section'
type ClickableSectionTrackIds =
  | 'paper-check-settings'
  | 'pay-card-settings'
  | 'pay-card-faq'

export const trackClickableSection = (
  actionTrackId: ClickableSectionTrackIds
): DataToastTrackIdProps => {
  return track(`employee-payment-method.clickable-section.${actionTrackId}`)
}

type ActionButtonTrackId = 'action-button'
type ActionButtonTrackIds = 'legacy-direct-deposit' | 'plaid' | 'split-deposit'

type DirectDepositAccountsTrackId = 'direct-deposit-accounts'
type DirectDepositAccountsTrackIds =
  | 'initiate-delete'
  | 'delete'
  | 'cancel-delete'

export const trackDirectDepositAccounts = (
  actionTrackId: DirectDepositAccountsTrackIds
): DataToastTrackIdProps => {
  return track(
    `employee-payment-method.direct-deposit-accounts.${actionTrackId}`
  )
}

export const trackActionButton = (
  actionTrackId: ActionButtonTrackIds
): DataToastTrackIdProps => {
  return track(`employee-payment-method.action-button.${actionTrackId}`)
}

type DisclaimerTrackId = 'disclaimer'
type DisclaimerTrackIds =
  | 'sutton-bank-disclaimer.pay-card-terms-link'
  | 'pay-card-terms-link'

export const trackDisclaimer = (
  actionTrackId: DisclaimerTrackIds
): DataToastTrackIdProps => {
  return track(`employee-payment-method.disclaimer.${actionTrackId}`)
}

type PaperCheckConfirmationTrackId = 'paper-check-confirmation'
type PaperCheckConfirmationActionTrackIds = 'cancel' | 'confirm'

export const trackPaperCheckConfirmation = (
  actionTrackId: PaperCheckConfirmationActionTrackIds
): DataToastTrackIdProps => {
  return track(
    `employee-payment-method.paper-check-confirmation.${actionTrackId}`
  )
}

type SendDirectDepositInfoRequestModalTrackId =
  'send-direct-deposit-info-request-modal'
type SendDirectDepositInfoRequestModalActionTrackIds =
  | 'email-update'
  | 'invalid-email-update'
  | 'option-email'
  | 'option-manual'
  | 'close'
  | 'continue'

export const trackSendDirectDepositInfoRequestModal = (
  actionTrackId: SendDirectDepositInfoRequestModalActionTrackIds
): DataToastTrackIdProps => {
  return track(
    `employee-payment-method.send-direct-deposit-info-request-modal.${actionTrackId}`
  )
}

type DownloadAppTrackId = 'download-app'
type DownloadAppActionTrackIds =
  | 'close'
  | 'download'
  | 'pay-card-eligibility.terms'
  | 'sms-disclaimer.terms'
  | 'sms-disclaimer.privacy'
  | 'sutton-disclaimer'

export const trackDownloadApp = (
  actionTrackId: DownloadAppActionTrackIds
): DataToastTrackIdProps => {
  return track(`employee-payment-method.download-app.${actionTrackId}`)
}

type SendDownloadAppLinkTrackId = 'send-download-app-link'
type SendDownloadAppLinkActionTrackIds = 'close' | 'phone-number' | 'send-link'

export const trackSendDownloadAppLink = (
  actionTrackId: SendDownloadAppLinkActionTrackIds
): DataToastTrackIdProps => {
  return track(
    `employee-payment-method.send-download-app-link.${actionTrackId}`
  )
}

type SendDownloadAppLinkConfirmationTrackId =
  'send-download-app-link-confirmation'
type SendDownloadAppLinkConfirmationActionTrackIds = 'close' | 'confirm'

export const trackSendDownloadAppLinkConfirmation = (
  actionTrackId: SendDownloadAppLinkConfirmationActionTrackIds
): DataToastTrackIdProps => {
  return track(
    `employee-payment-method.send-download-app-link-confirmation.${actionTrackId}`
  )
}

type AddBankAccountPanelTrackId = 'add-bank-account-panel'
type AddBankAccountPanelTrackIds = 'add'

export const trackAddBankAccountPanel = (
  actionTrackId: AddBankAccountPanelTrackIds
): DataToastTrackIdProps => {
  return track(
    `employee-payment-method.add-bank-account-panel.${actionTrackId}`
  )
}

type DirectDepositSplitModal = 'direct-deposit-split-modal'
type DirectDepositSplitModalTrackIds =
  | 'close'
  | 'amount'
  | 'percent'
  | 'toggle-switch'
  | 'save'

export const trackDirectDepositSplitModal = (
  actionTrackId: DirectDepositSplitModalTrackIds
): DataToastTrackIdProps => {
  return track(
    `employee-payment-method.direct-deposit-split-modal.${actionTrackId}`
  )
}

export type TrackId =
  | `${App}.${PayCardAdTrackId}.${PayCardAdTrackIds}`
  | `${App}.${SelectorTrackId}.${SelectorTrackIds}`
  | `${App}.${ClickableSectionTrackId}.${ClickableSectionTrackIds}`
  | `${App}.${DirectDepositAccountsTrackId}.${DirectDepositAccountsTrackIds}`
  | `${App}.${ActionButtonTrackId}.${ActionButtonTrackIds}`
  | `${App}.${AddBankAccountPanelTrackId}.${AddBankAccountPanelTrackIds}`
  | `${App}.${DisclaimerTrackId}.${DisclaimerTrackIds}`
  // not main page (modals and such)
  | `${App}.${MissingEmployeeInfoTrackId}.${MissingEmployeeInfoActionTrackIds}`
  | `${App}.${PaperCheckConfirmationTrackId}.${PaperCheckConfirmationActionTrackIds}`
  | `${App}.${SendDirectDepositInfoRequestModalTrackId}.${SendDirectDepositInfoRequestModalActionTrackIds}`
  | `${App}.${DownloadAppTrackId}.${DownloadAppActionTrackIds}`
  | `${App}.${SendDownloadAppLinkTrackId}.${SendDownloadAppLinkActionTrackIds}`
  | `${App}.${SendDownloadAppLinkConfirmationTrackId}.${SendDownloadAppLinkConfirmationActionTrackIds}`
  | `${App}.${DirectDepositSplitModal}.${DirectDepositSplitModalTrackIds}`

const track = (trackId: TrackId): { 'data-toast-track-id': TrackId } =>
  ecTrack(trackId) as DataToastTrackIdProps

export const useTracking = (): { track: (trackId: TrackId) => void } => {
  const { track: ecTrack } = useEcTracking()
  const track = (trackId: TrackId) => {
    ecTrack(trackId)
  }
  return { track }
}
