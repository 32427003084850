import * as React from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useEmployee, useUser } from '@local/contexts'
import { trackSendDirectDepositInfoRequestModal } from '@local/track'
import { Trans } from '@local/translations'

type InvalidEmailAlertProps = {
  isEmailValid: boolean
}

export const InvalidEmailAlert: React.FunctionComponent<
  InvalidEmailAlertProps
> = ({ isEmailValid }) => {
  const client = useUser().client
  const employeeId = useEmployee().id

  return isEmailValid ? null : (
    <Alert>
      <Trans
        i18nKey='direct-deposit-modal.invalid-email'
        components={[
          <a
            {...trackSendDirectDepositInfoRequestModal('invalid-email-update')}
            className='inline-link-inherit'
            key='invalid-email-update'
            href={`/${client}/employees/${employeeId}/account`}
            rel='noopener noreferrer'
          />
        ]}
      />
    </Alert>
  )
}
