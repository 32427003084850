import {
  useEmployee,
  useIsDirectDepositSelected,
  useIsViewingSelf
} from '@local/hooks'
import { trackActionButton } from '@local/track'
import { ButtonProps } from '@toasttab/buffet-pui-buttons'
import { SplitIcon } from '@toasttab/buffet-pui-icons'
import { useNavigateToLegacyDirectDepositLink } from '@toasttab/ec-session'
import { t } from '@local/translations'

export const useDirectDepositButtonProps = (
  openPlaidModal: VoidFunction,
  openSplitModal: VoidFunction
): Pick<ButtonProps, 'children' | 'onClick' | 'iconLeft'> | null => {
  const isDirectDepositSelected = useIsDirectDepositSelected()
  const isViewingSelf = useIsViewingSelf()
  const bankAccountCount = useEmployee().bankAccounts?.length || 0

  const navigateToLegacyLink = useNavigateToLegacyDirectDepositLink(
    useEmployee().id
  )

  if (!isDirectDepositSelected) {
    return null
  }

  if (!isViewingSelf) {
    return {
      ...trackActionButton('legacy-direct-deposit'),
      children: t('direct-deposit-button.view'),
      onClick: navigateToLegacyLink
    }
  }

  if (bankAccountCount < 2) {
    return {
      ...trackActionButton('plaid'),
      children: t('direct-deposit-button.add'),
      iconLeft: <SplitIcon accessibility='decorative' />,
      onClick: openPlaidModal
    }
  }

  // If an employee has more than two bank accounts there is a check on the backend that prevents more from being added
  if (bankAccountCount === 2) {
    return {
      ...trackActionButton('split-deposit'),
      children: t('direct-deposit-button.split'),
      iconLeft: <SplitIcon accessibility='decorative' />,
      onClick: openSplitModal
    }
  }

  // backend restriction, more than two accounts can't be edited
  // https://github.com/toasttab/ec-human-resources/blob/42ec7d164307a76b3ab35cba906beae058d45925/human-resources/human-resources-core/src/main/kotlin/com/toasttab/ec/humanResources/employee/payment/EmployeePayment.kt#L29
  if (bankAccountCount > 2) {
    return {
      ...trackActionButton('legacy-direct-deposit'),
      children: t('direct-deposit-button.view'),
      onClick: navigateToLegacyLink
    }
  }

  return null
}
