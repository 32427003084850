import * as React from 'react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { useField } from 'formik'

import { CurrencyField } from '@toasttab/buffet-pui-forms'
import { Currency } from '@toasttab/buffet-pui-types'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'

import { trackDirectDepositSplitModal } from '@local/track'
import { BankAccount } from '../../../../api-documents'

import { t } from '@local/translations'

const columnHelper = createColumnHelper<BankAccount>()
export const useAmountSplitModalTableColumns = (): ColumnDef<
  BankAccount,
  any
>[] => {
  return React.useMemo(
    () => [
      columnHelper.accessor('bankName', {
        id: 'bank-name',
        header: t('direct-deposit-split-modal.bank-name-header'),
        cell: (info) => {
          return <TextCell name={`bankAccounts.${info.row.index}.bankName`} />
        }
      }),
      columnHelper.accessor('accountNumber', {
        id: 'account-number',
        header: t('direct-deposit-split-modal.bank-account-header'),
        cell: (info) => {
          return (
            <TextCell name={`bankAccounts.${info.row.index}.accountNumber`} />
          )
        }
      }),
      columnHelper.accessor((originalRow) => originalRow, {
        id: 'amount',
        header: t('direct-deposit-split-modal.amount-header'),
        cell: (info) => {
          const isLast =
            info.row.index + 1 === info.table.getRowModel().rows.length
          return isLast ? (
            <div className='flex items-center'>
              {t('direct-deposit-split-modal.remainder')}
              <InfoTooltip placement='bottom'>
                {t('direct-deposit-split-modal.remainder-tooltip')}
              </InfoTooltip>
            </div>
          ) : (
            <CurrencyField
              {...trackDirectDepositSplitModal('amount')}
              allowNegative={false}
              className='text-left'
              currency={Currency.USD}
              decimalScale={0}
              id={info.row.original.id}
              maxLength={20}
              name={`bankAccounts.${info.row.index}.amount`}
              required
              thousandSeparator={true}
            />
          )
        }
      })
    ],
    [t]
  )
}

type TextCellProps = {
  name: string
}

const TextCell: React.FunctionComponent<TextCellProps> = (props) => {
  const [field] = useField(props)
  return <>{field.value}</>
}
