import * as React from 'react'
import { useQuery } from '@apollo/client'

import {
  EmployeeContextProvider,
  EmployeeContextValue,
  UserContextProvider,
  UserContextUser
} from '@local/contexts'
import {
  EmployeeForAppContext,
  EmployeePayCardAccess,
  useBankAccountsQuery,
  usePaymentMethodQuery
} from './api-documents'

import {
  getEmployeeFirstName,
  getEmployeePayCardAccessLevel,
  isEmployeeMissingCriticalInfo
} from './utils'
import { MissingEmployeeInfoPage } from './missingEmployeeInfoPage'
import { t } from '@local/translations'

type AppContextProps = {
  /** the user **/
  user: UserContextUser
  /** the employee id currently being accessed **/
  employeeId: string
  /** the React component to render within the context of the customer and employee **/
  children: React.ReactNode
}

/** Make available all the shared app data such as the user and employee **/
export const AppContext: React.FunctionComponent<AppContextProps> = ({
  user,
  employeeId,
  children
}) => {
  const [userContextValue] = React.useState<UserContextUser>(() => user)
  const [employeeContextValue, setEmployeeContextValue] = React.useState<
    EmployeeContextValue | undefined
  >(() => undefined)

  const { data: employeeData, loading: employeeLoading } = useQuery(
    EmployeeForAppContext,
    {
      variables: { employeeId }
    }
  )

  const {
    data: payCardEmployeeAccessData,
    loading: payCardEmployeeAccessLoading
  } = useQuery(EmployeePayCardAccess, {
    variables: {
      input: {
        employeeUuid: employeeId,
        skipCache: false
      }
    }
  })

  const { isPending: paymentMethodLoading, data: paymentMethodData } =
    usePaymentMethodQuery(user.client, employeeId)

  const { isPending: bankAccountsLoading, data: bankAccountsData } =
    useBankAccountsQuery(user.client, employeeId)

  React.useEffect(() => {
    if (
      !employeeLoading &&
      !payCardEmployeeAccessLoading &&
      !paymentMethodLoading &&
      !bankAccountsLoading
    ) {
      setEmployeeContextValue({
        id: employeeId,
        firstName:
          getEmployeeFirstName(employeeData) ||
          t('employee-first-name.default'),
        payCardAccessLevel: getEmployeePayCardAccessLevel(
          payCardEmployeeAccessData
        ),
        paymentMethod: paymentMethodData?.type,
        bankAccounts: bankAccountsData || [],
        splitAccounts: paymentMethodData?.obj?.splitAccounts || []
      })
    }
  }, [
    employeeId,
    employeeLoading,
    employeeData,
    payCardEmployeeAccessLoading,
    payCardEmployeeAccessData,
    paymentMethodLoading,
    paymentMethodData,
    bankAccountsLoading,
    bankAccountsData
  ])

  const employeeIsMissingCriticalInfo = React.useMemo(
    () => isEmployeeMissingCriticalInfo(employeeData),
    [employeeData]
  )

  // todo add loading skeleton
  return userContextValue && employeeContextValue ? (
    <UserContextProvider value={userContextValue}>
      <EmployeeContextProvider value={employeeContextValue}>
        {employeeIsMissingCriticalInfo ? <MissingEmployeeInfoPage /> : children}
      </EmployeeContextProvider>
    </UserContextProvider>
  ) : null
}
