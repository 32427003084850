import { graphql } from '@local/grouter'

export const EmployeeForAppContext = graphql(`
  query EmployeeForAppContext($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      user {
        username
        email
        name {
          first
          chosen
        }
      }
    }
  }
`)
