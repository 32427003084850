import { CardSelectorItem } from '@toasttab/buffet-pui-card-selector'

import {
  useEmployee,
  useEmployeeHasActivePayCard,
  useIsDirectDepositSelected,
  useIsPaperCheckSelected,
  useIsPayCardEnrollmentEnabled,
  useIsPayCardSelected,
  useIsViewingSelf
} from '@local/hooks'
import { trackSelector } from '@local/track'
import { PaymentMethodMutationProps } from './api-documents'
import { t } from '@local/translations'

export const usePaymentMethodOptions = (
  paymentMethodMutation: {
    mutate: (props: PaymentMethodMutationProps) => void
  },
  openPlaidModal: VoidFunction,
  openDirectDepositModal: VoidFunction,
  openPaperCheckConfirmation: VoidFunction
): CardSelectorItem[] => {
  const payCardOption = usePayCardOption(paymentMethodMutation)
  const directDepositOption = useDirectDepositOption(
    paymentMethodMutation,
    openPlaidModal,
    openDirectDepositModal
  )
  const paperCheckOption = usePaperCheckOption(openPaperCheckConfirmation)

  const isPayCardEnrollmentEnabled = useIsPayCardEnrollmentEnabled()
  const hasActivePayCard = useEmployeeHasActivePayCard()

  const options: CardSelectorItem[] = [directDepositOption, paperCheckOption]

  if (isPayCardEnrollmentEnabled) {
    if (hasActivePayCard) {
      options.unshift(payCardOption)
    } else {
      options.push(payCardOption)
    }
  }
  return options
}

const usePayCardOption = (paymentMethodMutation: {
  mutate: (props: PaymentMethodMutationProps) => void
}): CardSelectorItem => {
  const isViewingSelf = useIsViewingSelf()
  const hasActivePayCard = useEmployeeHasActivePayCard()
  const isSelected = useIsPayCardSelected()

  return {
    ...trackSelector('pay-card'),
    value: 'PAY_CARD',
    title: t('payment-method-selector.pay-card.title'),
    contents: t(
      `payment-method-selector.pay-card.description${
        isViewingSelf ? '.self' : ''
      }${hasActivePayCard ? '.activated' : ''}`
    ),
    checked: isSelected,
    disabled: !hasActivePayCard,
    onClick: !isSelected
      ? () => {
          paymentMethodMutation.mutate({
            paymentMethod: 'PayByPayCard'
          })
        }
      : undefined
  }
}

const useDirectDepositOption = (
  paymentMethodMutation: {
    mutate: (props: PaymentMethodMutationProps) => void
  },
  openPlaidModal: VoidFunction,
  openDirectDepositModal: VoidFunction
): CardSelectorItem => {
  const isViewingSelf = useIsViewingSelf()
  const isSelected = useIsDirectDepositSelected()

  const bankAccountsWithoutPayCard = useEmployee().bankAccounts.filter(
    (acct) => acct.accountType !== 'paycard'
  )

  return {
    ...trackSelector('direct-deposit'),
    value: 'DIRECT_DEPOSIT',
    title: t('payment-method-selector.direct-deposit.title'),
    contents: t(
      `payment-method-selector.direct-deposit.description${
        isViewingSelf ? '.self' : ''
      }`
    ),
    checked: isSelected,
    onClick: !isSelected
      ? () => {
          if (isViewingSelf && bankAccountsWithoutPayCard.length === 0) {
            openPlaidModal()
          } else if (
            !isViewingSelf &&
            bankAccountsWithoutPayCard.length === 0
          ) {
            openDirectDepositModal()
          } else if (
            bankAccountsWithoutPayCard.length === 1 ||
            bankAccountsWithoutPayCard.length > 2
          ) {
            paymentMethodMutation.mutate({
              paymentMethod: 'PayByLegacyDirectDeposit'
            })
          } else if (
            bankAccountsWithoutPayCard.some(
              (acct) => acct?.amount && acct.amount > 0
            )
          ) {
            paymentMethodMutation.mutate({
              paymentMethod: 'PayByDirectDepositAmountSplit'
            })
          } else {
            paymentMethodMutation.mutate({
              paymentMethod: 'PayByDirectDepositPercentageSplit'
            })
          }
        }
      : undefined
  }
}

const usePaperCheckOption = (
  showPaperCheckConfirmation: VoidFunction
): CardSelectorItem => {
  const isViewingSelf = useIsViewingSelf()
  const isSelected = useIsPaperCheckSelected()
  return {
    ...trackSelector('paper-check'),
    value: 'PAPER_CHECK',
    title: t('payment-method-selector.paper-check.title'),
    contents: t(
      `payment-method-selector.paper-check.description${
        isViewingSelf ? '.self' : ''
      }`
    ),
    checked: isSelected,
    onClick: !isSelected ? showPaperCheckConfirmation : undefined
  }
}
