import * as React from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useOnPlaidEvent, useOnPlaidExit, useOnPlaidSuccess } from './utils'
import { PlaidErrorModal, PlaidErrorModalProps } from './PlaidErrorModal'
import { usePlaidLinkTokenQuery } from './api-documents'
import {
  useEmployee,
  useEmployeeDirectDepositBankAccounts
} from '@local/contexts'
import { useNavigateToLegacyDirectDepositLink } from '@toasttab/ec-session'

type PlaidModalProps = {
  onClose: VoidFunction
  openSplitDepositModal?: VoidFunction
}

export const PlaidModal: React.FunctionComponent<PlaidModalProps> = ({
  onClose,
  openSplitDepositModal
}) => {
  const [plaidErrorModal, setPlaidErrorModalProps] = React.useState<{
    isOpen: boolean
    props?: Pick<PlaidErrorModalProps, 'institutionNotFound'>
  }>({ isOpen: true })

  const [token, setToken] = React.useState<string | null>(null)
  const { data } = usePlaidLinkTokenQuery(!token)

  const navigateToLegacyDirectDepositLink =
    useNavigateToLegacyDirectDepositLink(useEmployee().id)

  React.useEffect(() => {
    setToken(data?.token || null)
    if (
      data?.error?.message === 'humanResources.plaidLinkInitializationError'
    ) {
      navigateToLegacyDirectDepositLink()
    }
  }, [data, navigateToLegacyDirectDepositLink])

  const bankAccounts = useEmployeeDirectDepositBankAccounts()
  const onSuccess = useOnPlaidSuccess(() => {
    if (!!openSplitDepositModal && bankAccounts.length > 0) {
      openSplitDepositModal()
    }
    onClose()
  })
  const onExit = useOnPlaidExit(setPlaidErrorModalProps, onClose)
  const onEvent = useOnPlaidEvent()

  const { open, ready } = usePlaidLink({
    token: token,
    onSuccess: onSuccess,
    onExit: onExit,
    onEvent: onEvent
  })

  React.useEffect(() => {
    if (ready) {
      open()
    }
  }, [ready, open])

  return (
    <>
      {plaidErrorModal.isOpen && plaidErrorModal.props && (
        <PlaidErrorModal
          {...plaidErrorModal.props}
          onClose={() => {
            setPlaidErrorModalProps({ isOpen: false })
            onClose()
          }}
        />
      )}
    </>
  )
}
