import * as React from 'react'
import { Image } from '@toasttab/buffet-pui-image'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { TWO_TILTED_PAYCARDS_IMAGE_URL } from '../../payCard'
import { t } from '@local/translations'

export const HeaderContent: React.FunctionComponent<TestIdentifiable> = ({
  testId
}) => {
  return (
    <div
      data-testid={`${testId}-banner-header`}
      className=' mb-2 text-center justify-center'
    >
      <Image
        testId={`${testId}-image`}
        alt={t('image-alt.pay-card')}
        className='w-2/5 mb-6'
        fit='contain'
        src={TWO_TILTED_PAYCARDS_IMAGE_URL}
      />
      <div
        data-testid={`${testId}-header-text`}
        className='font-bold type-headline-5'
      >
        {t('download-app-modal.header')}
      </div>
    </div>
  )
}
